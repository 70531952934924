<template>
  <section :class="$customSectionClasses(props.cfgs)">
    <div class="container">
      <div
        v-if="values.title"
        class="h2"
      >
        {{ values.title }}
      </div>
      <div class="image-block__wrapper ">
        <swiper
          :space-between="30"
          :slides-per-view="1"
          :centered-slides="false"
          :effect="'fade'"
          class="image-block__slider "
          @swiper="sliderImageBlockSwiper.setSliderRef"
        >
          <swiper-slide
            v-for="slide in values.images"
            :key="`propmoi-home-${slide.image}`"
            class="adaptive-img image-block__slide"
          >
            <NuxtImg
              fit="crop"
              class="link-absolute"
              alt="slider"
              :height="getImageParamsByKey(configs, 'height', DEFAULT_IMAGE_HEIGHT)"
              :width="getImageParamsByKey(configs, 'width', DEFAULT_IMAGE_WIDTH)"
              loading="lazy"
              :placeholder="true"
              :src="String(slide.image)"
            />
            <div class="slider-shadow-bottom"></div>
          </swiper-slide>
        </swiper>
      </div>

      <div
        v-if="values.images && values.images?.length > 1"
        class="image-block__controls"
      >
        <div class="slider-nav-count"><span>{{ activeIndex }}</span> — <span>{{ values.images?.length }}</span></div>
        <div class="image-block__nav slider-nav">
          <button
            :class="{'disabled': sliderImageBlockSliderRef?.isBeginning}"
            class="slider-nav-btns --prev"
            @click="sliderImageBlockSwiper.prevSlide"
          />

          <button
            :class="{'disabled':sliderImageBlockSliderRef?.isEnd}"
            class="slider-nav-btns --next"
            @click="sliderImageBlockSwiper.nextSlide"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { Swiper as SwiperClass } from 'swiper/types';
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { SectionTypes } from '~/plugins/customSectionClasses';
import { setCustomSwiper } from '~/utils/swiper/setCustomSwiper';
import getImageParamsByKey from "~/utils/images/getImageParamsByKey";

type SliderImageBlockType = Omit<IBlockDefaultProps, 'values' | 'cfgs' | 'extras'> & {
	values: {
    title: string;
		images: {
			image: number;
		}[]
	};
	cfgs: SectionTypes;
}
const props = defineProps<SliderImageBlockType>();
const configs = props.cfgs

const DEFAULT_IMAGE_WIDTH = 1280
const DEFAULT_IMAGE_HEIGHT = 610

const { $customSectionClasses, $api } = useNuxtApp();

const sliderImageBlockSliderRef = ref<SwiperClass | null>(null);
const sliderImageBlockSwiper = setCustomSwiper(sliderImageBlockSliderRef);

const activeIndex = computed(() => {
  if (sliderImageBlockSliderRef.value) {
    return sliderImageBlockSliderRef.value.activeIndex + 1;
  }
  return 1;
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.custom-section{
	z-index: 11;
}

.image-block__wrapper {

}

.image-block__slider {
	position: relative;
}

.image-block__slide {
		max-height: 610px;
    position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 38px;
    opacity: 0.3;
    background: linear-gradient(180deg, #000 0%, rgb(0 0 0 / 0%) 100%);
    top: 0;
    left: 0;
    position: absolute;
    z-index: 2;
    border-radius: 24px 24px 0 0;
  }

  & > .slider-shadow-bottom {
    width: 100%;
    height: 38px;
    opacity: 0.3;
    background: linear-gradient(360deg, #000 0%, rgb(0 0 0 / 0%) 100%);
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 2;
    border-radius: 0 0 24px 24px;
  }

	& > img {
		width: 100%;
		height: 100%;
		border-radius: 24px;
		object-fit: cover;
    position: absolute;
	}
}

.image-block__nav {
	position: relative;
	bottom: initial;
	right: initial;
	justify-content: flex-end;
	gap: 10px;

}

.image-block__controls {
	margin-top: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@include media('md') {
	.image-block__controls {
		margin-top: 30px;
	}
}
</style>
